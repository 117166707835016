<template>
 <div>
  <b-card bg-variant="light">
    <div v-if="showCollectionSelection">
      <collection-select @selectedItems="setCollectionId"></collection-select>
    </div>

    <b-form-group 
      label-cols-lg="3"
      label="Work Group"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Name:"
        label-for="nested-name"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-name"
          v-model="attr_copy['name']"
        ></b-form-input>
      </b-form-group>
    </b-form-group>
    <b-row>
      <b-col>
        <b-button block variant="primary" @click="callApi">Add</b-button>
      </b-col>
      <b-col>
        <b-button block variant="primary" @click="callCancel">Cancel</b-button>
      </b-col>
    </b-row>  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import {amsMofifiedAttrs} from '@/utils'
import {amsSchema} from '@/schema'
import CollectionSelect from './CollectionSelect.vue'

export default {
  components: {
    CollectionSelect
  },
  props: {
    reroute: {
      Type: Boolean,
      default: true
    },
    ams_template: {
      Type: Object
    },
    collection_id: {
      Type: String
    }
  },
  data () {
    return {
      attr_copy: {},
      attr_orig: {},
    }
  },
  computed: {
    showCollectionSelection() {
      return !(this.attr_copy['collection-id']?.length)
    }
  },
  methods: {
    setCollectionId(items) {
      this.attr_copy['collection-id'] = items[0].id
    },
    callApi () {
      let attr_params = amsMofifiedAttrs(this.attr_copy, this.attr_orig)
      http_v1.post (
        'api/admin/v1/work_groups', {
          ...attr_params
        }
      ).then(response => {
        if( response.data) {
          this.$emit('takeAction')
          if(this.reroute) {
            this.$router.go(-1)
          }
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
    callCancel() {
      this.$emit('noAction')
      if(this.reroute) {
        this.$router.go(-1)
      }
    }
  },
  mounted() {
    if(this.ams_template?.attributes) {
      this.attr_copy = {...this.ams_template.attributes}
    }
    else {
      const included_obj = amsSchema.find( 
        el =>  
        el.type === 'work-groups'
      )
      this.attr_copy = {...included_obj.attributes}
      this.attr_copy['collection-id'] = this.collection_id
    }
  }
}
</script>